<template>
   <v-layout align-start>
      <v-flex xs12>
         <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :right="true"
            :bottom="true"
            :timeout="4000"
         >
            {{ snackText }}
            <template #action="{ attrs }">
               <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
               >
                  Cerrar
               </v-btn>
            </template>
         </v-snackbar>

         <!-- DIALOG CREAR / EDITAR  -->
         <v-dialog v-model="dialogCreateEdit" persistent max-width="500px">
            <v-form
               ref="formProgramas"
               v-model="validProgramas"
               lazy-validation
            >
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium">{{
                                 formTitle
                              }}</span>
                           </v-flex>
                        </v-layout>
                        <v-layout wrap>
                           <v-flex xs12 sm3>
                              <v-text-field
                                 v-model="codigo"
                                 :readonly="editedIndex != -1"
                                 :disabled="editedIndex != -1"
                                 :rules="rulesCodigo"
                                 color="primary"
                                 label="Código"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm9>
                              <v-text-field
                                 v-model="nombre"
                                 :rules="rulesNombre"
                                 color="primary"
                                 label="Nombre"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm6>
                              <v-dialog
                                 ref="mHoraInicio"
                                 v-model="mHoraInicio"
                                 :return-value.sync="inicio"
                                 persistent
                                 width="350px"
                              >
                                 <template #activator="{ on, attrs }">
                                    <v-text-field
                                       v-model="inicio"
                                       label="Hora de inicio"
                                       prepend-icon="far fa-clock"
                                       color="primary"
                                       readonly
                                       v-bind="attrs"
                                       v-on="on"
                                    ></v-text-field>
                                 </template>
                                 <v-time-picker
                                    v-if="mHoraInicio"
                                    v-model="inicio"
                                    ampm-in-title
                                    scrollable
                                    color="primary"
                                    use-seconds
                                    full-width
                                 >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                       text
                                       color="primary"
                                       @click="mHoraInicio = false"
                                    >
                                       Cancelar
                                    </v-btn>
                                    <v-btn
                                       color="primary"
                                       @click="$refs.mHoraInicio.save(inicio)"
                                    >
                                       Guardar
                                    </v-btn>
                                 </v-time-picker>
                              </v-dialog>
                           </v-flex>
                           <v-flex xs12 sm6>
                              <v-dialog
                                 ref="mHoraFin"
                                 v-model="mHoraFin"
                                 :return-value.sync="fin"
                                 persistent
                                 width="350px"
                              >
                                 <template #activator="{ on, attrs }">
                                    <v-text-field
                                       v-model="fin"
                                       label="Hora de finalización"
                                       prepend-icon="far fa-clock"
                                       color="primary"
                                       readonly
                                       v-bind="attrs"
                                       v-on="on"
                                    ></v-text-field>
                                 </template>
                                 <v-time-picker
                                    v-if="mHoraFin"
                                    v-model="fin"
                                    scrollable
                                    ampm-in-title
                                    color="primary"
                                    use-seconds
                                    full-width
                                 >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                       text
                                       color="primary"
                                       @click="mHoraFin = false"
                                    >
                                       Cancelar
                                    </v-btn>
                                    <v-btn
                                       color="primary"
                                       @click="$refs.mHoraFin.save(fin)"
                                    >
                                       Guardar
                                    </v-btn>
                                 </v-time-picker>
                              </v-dialog>
                           </v-flex>
                           <v-flex xs12 sm5>
                              <v-autocomplete
                                 v-model="selectIdioma"
                                 :items="cmbIdiomas"
                                 item-value="idiomaId"
                                 color="primary"
                                 item-text="nombre"
                              ></v-autocomplete>
                           </v-flex>
                           <v-flex xs12 sm3>
                              <v-text-field
                                 v-model="cupos"
                                 color="primary"
                                 label="Cupos (Nivel 1)"
                              >
                              </v-text-field>
                           </v-flex>
                           <v-flex xs12 sm4>
                              <v-layout class="justify-center">
                                 <v-switch
                                    v-model="activo"
                                    inset
                                    :label="activo ? 'Activo' : 'Inactivo'"
                                    color="primary"
                                 ></v-switch>
                              </v-layout>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -20px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn color="primary" text @click.native="close"
                        >Cancelar</v-btn
                     >
                     <v-btn
                        color="primary white--text"
                        :disabled="!validProgramas"
                        @click.native="save"
                        >Guardar</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <!-- DIALOG PROGRAMAS REGISTRO  -->
         <v-dialog v-model="dialogRegistro" persistent max-width="700px">
            <v-form
               ref="formProgramas"
               v-model="validProgramas"
               lazy-validation
            >
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -10px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h6 font-weight-medium">
                                 Programas activos segun configuración de
                                 Registro Académico
                              </span>
                           </v-flex>
                           <v-flex xs12>
                              <v-simple-table>
                                 <template #default>
                                    <thead>
                                       <tr>
                                          <th class="text-left">Código</th>
                                          <th class="text-left">Programa</th>
                                          <th class="text-left">Idioma</th>
                                          <th class="text-left">Tipo</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr
                                          v-for="item in progRegistro"
                                          :key="item.name"
                                       >
                                          <td>{{ item.codigo }}</td>
                                          <td>
                                             {{
                                                item.descripcionEsp.replace(
                                                   /\(.+?\)/,
                                                   ''
                                                )
                                             }}
                                          </td>
                                          <td>
                                             {{
                                                item.idioma == 'EN'
                                                   ? 'Inglés'
                                                   : 'Francés'
                                             }}
                                          </td>
                                          <td>
                                             {{
                                                item.tipo == 1
                                                   ? 'Intensivo'
                                                   : 'Sabatino'
                                             }}
                                          </td>
                                       </tr>
                                    </tbody>
                                 </template>
                              </v-simple-table>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -20px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn
                        color="primary white--text"
                        @click="dialogRegistro = false"
                        >Salir</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <v-card elevation="0">
            <v-toolbar color="primary" dark>
               <v-toolbar-title style="font-weight: 500"
                  >Programas de idiomas</v-toolbar-title
               >
               <v-spacer></v-spacer>
               <v-text-field
                  ref="txtBusqueda"
                  v-model="search"
                  class="mt-3"
                  color="white"
                  append-icon="fa-search"
                  label="Búsqueda"
                  single-line
               ></v-text-field>
               <v-spacer></v-spacer>
               <v-btn primary text @click="dialogRegistro = true"
                  >Registro</v-btn
               >
               <v-btn
                  v-if="false"
                  text
                  icon
                  color="white darken-3"
                  @click="nuevoPrograma()"
               >
                  <v-icon>fas fa-plus</v-icon>
               </v-btn>
            </v-toolbar>

            <!-- DATATABLE programas -->
            <v-data-table
               :headers="headers"
               :items="programas"
               :search="search"
               :footer-props="{
                  itemsPerPageOptions: [10, 25, 50],
                  itemsPerPageText: 'Filas por página:'
               }"
               no-results-text="Búsqueda sin resultados"
               class="elevation-1"
            >
               <template #item.activo="{ item }">
                  <v-tooltip
                     v-if="item.activo"
                     top
                     max-width="300"
                     color="primary"
                  >
                     <template #activator="{ on }">
                        <v-icon color="primary" v-on="on"
                           >fas fa-toggle-on</v-icon
                        >
                     </template>
                     <span style="font-size: 15px"> Si </span>
                  </v-tooltip>
                  <v-tooltip v-else top max-width="300" color="grey darken-1">
                     <template #activator="{ on }">
                        <v-icon color="grey" v-on="on"
                           >fas fa-toggle-off</v-icon
                        >
                     </template>
                     <span style="font-size: 15px"> No </span>
                  </v-tooltip>
               </template>

               <template #item.opciones="{ item }">
                  <v-tooltip bottom color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           color="amber darken-3"
                           v-on="on"
                           @click="editItem(item)"
                        >
                           <v-icon>fa-pen</v-icon>
                        </v-btn>
                     </template>
                     <span>Editar</span>
                  </v-tooltip>
                  <v-btn
                     v-if="false"
                     text
                     small
                     fab
                     color="primary"
                     @click="deleteItem(item)"
                  >
                     <v-icon>fa-trash-alt</v-icon>
                  </v-btn>
               </template>

               <!-- Si no hay datos, mostrar boton para listar de nuevo -->
               <template slot="no-data">
                  <div v-if="programas.length == 0">
                     <v-container>
                        <v-layout wrap align-center>
                           <v-flex xs12 class="text-center">
                              <v-btn color="primary" @click="reset"
                                 >Resetear</v-btn
                              >
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </div>
               </template>
            </v-data-table>
         </v-card>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'
import formatters from '../mixins/formattersLib'

export default {
   name: 'Programas',
   mixins: [formatters],

   data: () => ({
      // Variables
      dialogCreateEdit: false,
      dialogEliminar: false,
      dialogRegistro: false,
      editedIndex: -1,
      progRegistro: [],
      headers: [
         { text: 'ID', value: 'programaId', class: 'titulo' },
         { text: 'Código', value: 'codigo', class: 'titulo' },
         { text: 'Nombre', value: 'nombre', class: 'titulo' },
         { text: 'Hora inicio', value: 'inicio', class: 'titulo' },
         { text: 'Hora fin', value: 'fin', class: 'titulo' },
         { text: 'Idioma', value: 'idioma', class: 'titulo' },
         { text: 'Activo', value: 'activo', class: 'titulo', align: 'center' },
         {
            text: 'Cupos (Nivel 1)',
            value: 'cupos',
            class: 'titulo',
            align: 'center'
         },
         {
            text: 'Opciones',
            value: 'opciones',
            align: 'right',
            sortable: false,
            class: 'titulo'
         }
      ],

      // Variables de formularios
      search: '',
      pagination: {},
      result: [],
      programas: [],

      validProgramas: true,
      snackbar: false,
      snackText: '',
      snackColor: 'primary',

      // Propiedades programas
      programaId: '',
      codigo: '',
      nombre: '',
      mHoraInicio: false,
      inicio: '',
      mHoraFin: false,
      fin: '',
      selectIdioma: '',
      cmbIdiomas: [],
      idiomaNombre: '',
      activo: false,
      cupos: 0,

      // Reglas de validacion de formularios
      rulesCodigo: [(v) => !!v || 'Ingrese un código'],
      rulesNombre: [(v) => !!v || 'Ingrese un nombre']
   }),
   computed: {
      ...authComputed,
      // Titulo de dialog
      formTitle() {
         return this.editedIndex === -1 ? 'Nuevo programa' : 'Editar programa'
      }
   },
   watch: {
      dialogRegistro() {
         axios
            .get('api/ProgramaIdioma/activos')
            .then((response) => {
               this.progRegistro = response.data
            })
            .catch((error) => {
               console.log(error)
            })
      }
   },
   created() {
      //Validando acceso al componente
      if (this.permController(17, 'ver')) {
         this.listarProgramas()
         this.listarIdiomas()
      } else {
         this.$router.push({ name: 'inicio' })
      }
   },
   methods: {
      // Select an item
      assingItem(item) {
         this.programaId = item.programaId
         this.codigo = item.codigo
         this.nombre = item.nombre
         this.inicio = item.inicio
         this.fin = item.fin
         this.selectIdioma = item.idiomaId
         this.idiomaNombre = item.idiomaNombre
         this.activo = item.activo
         this.cupos = item.cupos
      },
      // Muestra el dialog para remover unaProgramasAlu
      deleteItem(item) {
         this.assingItem(item)
         this.dialogEliminar = true
      },
      // Autoseleccionar la busqueda al cambiar filtro de busquedad
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      // Popular el datatable
      listarProgramas() {
         // Obteniendo programas
         axios
            .get('api/ProgramaIdioma')
            .then((response) => {
               this.programas = response.data
            })
            .catch((error) => {
               console.log(error)
            })
      },
      // Popular autocomplete de idiomas
      listarIdiomas() {
         // Obteniendo programas
         axios
            .get('api/general/idioma')
            .then((response) => {
               this.cmbIdiomas = response.data
            })
            .catch((error) => {
               console.log(error)
            })
      },
      // Si no cargaron bien las programas en el datatable se puede resetear
      reset() {
         this.listarProgramas()
      },

      // Cargando informacion del item a editar
      editItem(item) {
         this.assingItem(item)
         this.editedIndex = 1
         this.dialogCreateEdit = true
         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      // Cerrar ventana de dialogo
      close() {
         this.dialogCreateEdit = false
         this.dialogEliminar = false
         this.clear()
         setTimeout(() => {
            this.editedIndex = -1
         }, 300)
      },
      // Guardar/Actulizar programas
      save() {
         if (this.$refs.formProgramas.validate()) {
            if (this.editedIndex > -1) {
               // Codigo para actualizar/editar
               axios
                  .put('api/ProgramaIdioma/' + this.programaId, {
                     codigo: this.codigo,
                     nombre: this.nombre,
                     inicio: this.inicio,
                     fin: this.fin,
                     idiomaId: this.selectIdioma,
                     activo: this.activo,
                     cupos: this.cupos
                  })
                  .then(() => {
                     this.listarProgramas()
                     this.close()
                     this.snackText = 'Programa editado exitosamente!'
                     this.snackColor = 'snackSuccess'
                     this.snackbar = true
                  })
                  .catch((error) => {
                     if (error.response.status == 304) {
                        this.snackText = 'Sin cambios.'
                        this.snackColor = 'snackInfo'
                     } else {
                        this.snackText = 'Algo a ido mal...'
                        this.snackColor = 'snackError'
                     }
                     this.snackbar = true
                     this.close()
                  })
            } else {
               // Codigo para guardar
               axios
                  .post('api/ProgramaIdioma', {
                     codigo: this.codigo,
                     nombre: this.nombre,
                     inicio: this.inicio,
                     fin: this.fin,
                     idiomaId: this.selectIdioma,
                     activo: this.activo,
                     cupos: this.cupos
                  })
                  .then(() => {
                     this.listarProgramas()
                     this.close()
                     this.snackText = 'Programa agregado exitosamente!'
                     this.snackColor = 'snackSuccess'
                     this.snackbar = true
                  })
                  .catch(() => {
                     this.snackText = 'No se pudo crear el programa.'
                     this.snackColor = 'snackError'
                     this.snackbar = true
                  })
            }
         }
      },
      // Limpiar formularios y componentes
      clear() {
         try {
            this.$refs.formProgramas.resetValidation()
         } catch (error) {
            //Do nothing
         }
         this.programaId = ''
         this.codigo = ''
         this.nombre = ''
         this.inicio = ''
         this.fin = ''
         this.selectIdioma = ''
         this.idiomaNombre = ''
         this.activo = ''
         this.cupos = 0
      },
      nuevoPrograma() {
         this.dialogCreateEdit = true
      }
   }
}
</script>
